import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ТехноСервіс Плюс
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:title"} content={"ТехноСервіс Плюс"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" />
			<Override slot="text1">
				Ваш надійний партнер у ремонті побутової техніки та електроніки
			</Override>
			<Override slot="text3" />
			<Override slot="text2">
				Ми розуміємо, наскільки важливою є справність вашої техніки у повсякденному житті, тому наша команда професіоналів завжди готова допомогти вам швидко та якісно. Наш сервісний центр оснащений найсучаснішим обладнанням, що дозволяє виконувати діагностику та ремонт будь-якої складності. Ми гарантуємо індивідуальний підхід до кожного клієнта та високий рівень сервісу.
			</Override>
			<Override slot="text" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Які послуги ми надаємо
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					"ТехноСервіс Плюс" пропонує широкий спектр послуг з ремонту техніки, включаючи ремонт побутової техніки, електроніки, комп'ютерів та мобільних пристроїв. Ми виконуємо ремонт будь-якої складності, від заміни дрібних деталей до складних електронних компонентів. Наші майстри мають великий досвід роботи та постійно підвищують свою кваліфікацію, щоб забезпечити вам найвищий рівень обслуговування.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12:20:25.524Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong
						sm-text-align="left"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Наші переваги
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому обирають нас?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Ми завжди враховуємо ваші потреби та побажання, пропонуючи оптимальні рішення для ремонту вашої техніки. Наша команда готова надати професійну консультацію та відповісти на всі ваші запитання, щоб ви могли зробити обґрунтований вибір.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Професіоналізм
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Наші майстри мають багаторічний досвід роботи у сфері ремонту побутової техніки та електроніки.{" "}
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Оригінальні запчастини
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми використовуємо лише оригінальні запчастини від провідних виробників техніки.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Гарантія на всі види робіт
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми надаємо гарантію на всі виконані нами роботи.{" "}
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Індивідуальний підхід
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Кожен клієнт для нас – особливий.{" "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						<Strong>
							Гарантії якості
						</Strong>
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Ми надаємо гарантію на всі види робіт, виконані нашими майстрами. Це означає, що ви можете бути впевнені у надійності та довговічності нашого ремонту. Якщо у вас виникнуть будь-які питання або проблеми після ремонту, наші спеціалісти завжди готові допомогти вам. Ми цінуємо вашу довіру та прагнемо забезпечити вам максимальний комфорт та безпеку.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12:20:25.523Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12:20:25.520Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Наші досягнення
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						З моменту заснування "ТехноСервіс Плюс" ми успішно відремонтували тисячі одиниць техніки та отримали безліч позитивних відгуків від задоволених клієнтів. Ми пишаємося нашими досягненнями та продовжуємо вдосконалювати наші послуги, щоб забезпечити вам найкращий сервіс. Наші клієнти завжди можуть розраховувати на нас, незалежно від складності проблеми.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link4">
				ТехноСервіс Плюс
			</Override>
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});